import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

export default function BrandCarousel({ data }) {
  return (
    <>
      <Swiper
        slidesPerView={1.8}
        spaceBetween={8}
        speed={600}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        breakpoints={{
          575: {
            slidesPerView: 1,
          },
          991: {
            slidesPerView: 4,
          },
          1300: {
            slidesPerView: 5,
          },
        }}
      >
        {data?.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="text-center">
              <img style={{width:"140px"}}  src={item.logoSrc} alt={item.alt} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
