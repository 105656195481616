import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import Portfolio from '../Portfolio';
import CtaStyle2 from '../Cta/CtaStyle2';
import { pageTitle } from '../../helpers/PageTitle';
const portfolioData = [
  {
    href: 'https://www.vaymode.com',
    subText:"Shopify Ecommerce Website Created For Vaymode. ",
    imgUrl: '/images/creative-agency/vaymodeWebsite.png',
    title: 'Vaymode : Shopify Ecommerce Website',
    btnText: 'See Project',
  },
  {
    href: 'https://dashboard.grcecom.com/',
    subText:"",
    imgUrl: '/images/creative-agency/grcdash.png',
    title: 'GRC : Logistics Dashboard',
    btnText: 'See Project',
  },
  {
    href: 'https://ekttaar.com/',
    subText:"",
    imgUrl: '/images/creative-agency/ekttaarWeb.png',
    title: 'Ekttaar : Business Website',
    btnText: 'See Project',
  },
  {
    href: 'https://www.unionliving.in/',
    subText:"",
    imgUrl: '/images/creative-agency/unionWeb.png',
    title: 'Unionliving : Coliving Space Website',
    btnText: 'See Project',
  },
  {
    href: 'https://salpepos.com/',
    imgUrl: '/images/creative-agency/salpeposWeb.png',
    title: 'Sal Pepos : Shopify Ecommerce Website',
    btnText: 'See Project',
  },
  {
    href: 'https://www.harshadpawar.com',
    imgUrl: '/images/creative-agency/portfolioWeb.png',
    title: 'Harshad Pawar : Cybersecurity Portfolio Website',
    btnText: 'See Project',
  },
  {
    href: 'https://maxconformance.com/',
    imgUrl: '/images/creative-agency/maxWebsite.png',
    title: 'Max Conformance : Wordpress SASS Business Website',
    btnText: 'See Project',
  },
  {
    href: 'https://introtelecom.in/',
    imgUrl: '/images/creative-agency/introWeb.png',
    title: 'Introtelecom : Business Website',
    btnText: 'See Project',
  },
];

export default function PortfolioPage() {
  pageTitle('Portfolio');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="We have completed a series of noteworthy projects"
        subTitle="Our Portfolio"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
        <Portfolio data={portfolioData} />
        <div className="cs_height_75 cs_height_lg_40" />
        <CtaStyle2
          title="Is there a specific project or goal <br />that you have in mind?"
          btnText="Send Message"
          btnUrl="/contact"
        />
        <div className="cs_height_150 cs_height_lg_80" />
      </div>
    </>
  );
}
