import React, { useState } from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import { pageTitle } from '../../helpers/PageTitle';
import { Icon } from '@iconify/react';
import EmailValidation from "emailvalid"
import {phone} from 'phone';
import { useMailChimp } from '../hooks/useMailChimp';
import { useNavigate } from 'react-router-dom';
export default function ContactPage() {
  pageTitle('Contact');
  const [popupInfo, setPopupInfo] = useState({
    name:"",
    email:"",
    contact:"",
    content:""
  })
  const navigate = useNavigate()
  const {subscribe, status, error, value} = useMailChimp("https://asynk.us13.list-manage.com/subscribe/post?u=2a379537464c9cf188e82d6cd&amp;id=c82c3013ed&amp;f_id=0058ebe1f0")
  const handleAddPopup = () =>{
    const ev = new EmailValidation({allowFreemail: true})
    const result = ev.check(popupInfo.email)
    const result2 = phone(popupInfo.contact, {country: "IND"})
    console.log(result2)
  if(popupInfo?.name?.length === 0){
        alert("Enter Valid Name")
    }
  else if(popupInfo?.email?.length === 0 || result.valid===false){
    alert("Enter Valid Email")
  }
  else if(popupInfo?.contact?.length === 0 || result2.isValid===false){
    console.log(result)
  alert("Enter Valid Contact")
  }
  else{
  
    subscribe({
      FNAME: popupInfo?.name,
    EMAIL: popupInfo?.email,
      PHONE: popupInfo?.contact
    })
   setPopupInfo({
    name:"",
    email:"",
    contact:"",
    content:""
   })
  }

  } 
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Get in touch for more <br/>information and support"
        subTitle="Contact"
        shape="shape_6"
      />
      <Spacing lg="75" md="60" />
      <section>
        <div className="container">
          <div className="row align-items-center cs_gap_y_45">
            <div className="col-lg-6">
              <h2 className="cs_fs_50">
                Come & visit <br />
                our place!
              </h2>
              <div className="cs_height_55 cs_height_lg_30" />
              <ul className="cs_mp0 cs_contact_info">
                <li>
                  <h3 className="cs_fs_29 cs_semibold">Email:</h3>
                  <p className="mb-0">work@asynk.in</p>
                </li>
                <li>
                  <h3 className="cs_fs_29 cs_semibold">Phone:</h3>
                  <p className="mb-0">+91 89288 84804</p>
                </li>
              
              </ul>
            </div>
            <div className="col-lg-6">
              <div className="cs_contact_form_wrap">
                <div className="cs_gray_bg_3 cs_contact_form_bg" />
                <form onSubmit={handleAddPopup} className="cs_contact_form">
                  <label className="cs_fs_21 cs_semibold cs_primary_color">
                    Your full name
                  </label>
                  <input
                  value={popupInfo.name}
                  onChange={(e)=>setPopupInfo({...popupInfo,name:e.target.value})}
                    placeholder="Type your name"
                    type="text"
                    className="cs_form_field"
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <label className="cs_fs_21 cs_semibold cs_primary_color">
                    Give valid email
                  </label>
                  <input
                  value={popupInfo.email}
                  onChange={(e)=>setPopupInfo({...popupInfo,email:e.target.value})}
                    placeholder="Type your email address"
                    type="text"
                    className="cs_form_field"
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <label className="cs_fs_21 cs_semibold cs_primary_color">
                    Phone number
                  </label>
                  <input
                    placeholder="Type your phone number"
                    value={popupInfo.contact}
                  onChange={(e)=>setPopupInfo({...popupInfo,contact:e.target.value})}
                    type="text"
                    className="cs_form_field"
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <label className="cs_fs_21 cs_semibold cs_primary_color">
                    Project brief
                  </label>
                  <textarea value={popupInfo.content} onChange={(e)=>setPopupInfo()} cols={30} rows={5} className="cs_form_field" />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <button type='submit' className="cs_btn cs_style_1" >
                    Send Message
                  </button>
                  
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="cs_height_0 cs_height_lg_80" />
      </section>
      {/* <div className="cs_map">
        <iframe
          id="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96652.27317354927!2d-74.33557928194516!3d40.79756494697628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3a82f1352d0dd%3A0x81d4f72c4435aab5!2sTroy+Meadows+Wetlands!5e0!3m2!1sen!2sbd!4v1563075599994!5m2!1sen!2sbd"
          allowFullScreen
          title="Google Map"
        />
      </div> */}
    </>
  );
}
